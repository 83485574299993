// @Note: リード用のオプトアウトフォーム
// ref: https://formspree.io/forms/mrgoyegb/submissions

import React from "react";
import { UnsubscribeForm } from "~/organisms/UnsubscribeForm";

const ENDPOINT = "https://formspree.io/f/mrgoyegb";
const UnsubscribeLeadsPage: React.FC = () => <UnsubscribeForm formspreeEndpoint={ENDPOINT} thanksSlug="leads" />;

export default UnsubscribeLeadsPage;
